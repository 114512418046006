import React from 'react';
import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';

import congesService from '@data/congesService';

import InputCustom from '@components/UI/InputCustom';
import ModalCustom from '@components/UI/ModalCustom';
import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';
import ToastCustom from '@components/UI/ToastCustom';

const TeamModal = ({
  isOpen,
  toggleTeamModal,
  tags,
  metaData,
  getUserCounters,
  teamModalData,
  findTag,
}) => {
  const { t } = useTranslation();

  const submitModal = (values, { setSubmitting }) => {
    const data = {
      user_id: teamModalData.id,
      attach: values.teamArray
        .filter((item) => item.checked)
        .map((item) => item.id),
      detach: values.teamArray
        .filter((item) => !item.checked)
        .map((item) => item.id),
    };

    congesService
      .post(`/v1/tags/multipleAttachDetach`, data)
      .then(() => {
        getUserCounters(metaData.current_page, []);
        ToastCustom.validated(t('updateSuccess'));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        toggleTeamModal();
        setSubmitting(false);
      });
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      onModalClose={toggleTeamModal}
      customClass={'w-8/12 md:w-[900px]'}
      title={t('teams')}
      children={
        <Formik
          initialValues={{
            teamArray: tags.map((tag) => ({
              id: tag.id,
              name: tag.label,
              checked: findTag(tag),
            })),
          }}
          onSubmit={submitModal}
        >
          {(props) => {
            return (
              <Form>
                <div className='grid grid-cols-3 gap-x-12 gap-y-4 my-8'>
                  {props.initialValues.teamArray.length ? (
                    props.initialValues.teamArray.map((team, index) => (
                      <div className='col-span-1 overflow-hidden' key={index}>
                        <InputCustom
                          type='checkbox'
                          label={team.name}
                          id={team.id}
                          checked={props.values.teamArray[index].checked}
                          name={`teamArray[${index}].checked`}
                        />
                      </div>
                    ))
                  ) : (
                    <span>{t('youHaveNoTeamYet')}</span>
                  )}
                </div>
                <div className='flex justify-end gap-x-5'>
                  <ButtonFirstAction type='button' onClick={toggleTeamModal}>
                    {t('cancel')}
                  </ButtonFirstAction>
                  <ButtonSecondAction
                    type='submit'
                    disabled={!props.dirty || props.isSubmitting}
                  >
                    {t('save')}
                  </ButtonSecondAction>
                </div>
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default TeamModal;
