import React from 'react';

import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';
import ModalCustom from '@components/UI/ModalCustom';
import InputCustom from '@components/UI/InputCustom';
import ToastCustom from '@components/UI/ToastCustom';

import congesService from '@data/congesService';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

const CreateModal = ({ isOpen, onModalClose, id, getTags }) => {
  const { t } = useTranslation();

  const handleSubmitRequest = (values, props) => {
    props.setSubmitting(true);
    congesService
      .post('v1/tags', values)
      .then(() => {
        getTags();
        onModalClose();
        ToastCustom.validated(t('createSuccess'));
      })
      .catch((err) => console.error(err))
      .finally(() => props.setSubmitting(false));
  };

  return (
    <ModalCustom
      title={t('createTeam')}
      isOpen={isOpen}
      customClass={'w-8/12 md:w-[900px]'}
      onModalClose={onModalClose}
      children={
        <>
          <Formik
            validate={(values) => {
              let errors = {};
              if (!values.label) {
                errors.label = t('requiredField');
              }
              return errors;
            }}
            onSubmit={handleSubmitRequest}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              label: '',
              user_id: id,
            }}
          >
            {(props) => (
              <Form>
                <InputCustom
                  label={t('label')}
                  placeholder='Enter team name'
                  name='label'
                  type='text'
                  containerClass='my-6'
                />
                <div className='flex justify-end gap-x-6'>
                  <ButtonFirstAction type='button' onClick={onModalClose}>
                    {t('cancel')}
                  </ButtonFirstAction>
                  <ButtonSecondAction
                    type='submit'
                    disabled={props.isSubmitting}
                  >
                    {t('continue')}
                  </ButtonSecondAction>
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default CreateModal;
